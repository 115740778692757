<template>
    <div class="modal-wrapper" v-if="show">
        <div class="modal-login">
            <button class="modal-close modal-close_sm" @click="hideModal">
                <span class="icon-close"></span>
            </button>
            <div class="modal-login__title">
                <span>Личный кабинет</span>
                <p v-if="userData.fio !== null">{{ userData.fio }}</p>
                <p v-else>{{ userData.username }}</p>
            </div>
            <div class="modal-login-list">
                <p>Ваши абонементы:</p>
                <p v-if="!userData.abonements.length" style="font-size: 18px;">У вас пока нет абонементов</p>
                <div class="modal-login-item" v-for="item in userData.abonements" :key="item.name">
                    <div>
                        <p>{{ item.name }}</p>
                        <span>Осталось {{ item.uses_left }} занятий</span>
                    </div>
                    <button class="btn">Продлить</button>
                </div>
            </div>
        </div>
        <div class="bg-blur"></div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/api'

export default {
    name: 'modalProfile',

    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            userData: {
                username: '',
                fio: '',
                abonements: {}
            },
            
        }
    },

    methods: {
        hideModal() {
            this.$emit('close')
        },

        fetchUserData() {
            axios
            .get(api + "/profile/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                },
            })
            .then((response) => {
                this.userData.fio = response.data.first_name
                this.userData.username = response.data.username
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchUserAbonements() {
            axios
            .get(api + "/abonements/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                },
            })
            .then((response) => {
                this.userData.abonements = response.data.results
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    },

    mounted() {
        if (localStorage.getItem('token') !== null ) {
            this.fetchUserData(),
            this.fetchUserAbonements()
        }
    }
}
</script>