<template>
    <div class="modal-wrapper" v-if="show">
        <form class="modal-login" v-on:submit="sendRequest">
            <button class="modal-close modal-close_sm" @click="hideModal">
                <span class="icon-close"></span>
            </button>
            <div class="modal-login__title modal-login__title_subs">
                <span>Абонемент</span>
                <p>{{ type }}</p>
            </div>
            <div class="modal-login__form">
                <input type="text" class="modal-input" v-model="data.name" placeholder="ФИО" required>
                <input type="tel" class="modal-input" v-model="data.birth_date" placeholder="Дата рождения" v-mask="'##.##.####'" required>
                <input type="tel" class="modal-input" v-model="data.phone" placeholder="Телефон" v-mask="'+7 (###) ###-##-##'" required>
                <multiselect class="subs" v-model="data.selectLocation" :options="optionsLocation" placeholder="Выберите адрес зала" :disabled="selectDisabled" required></multiselect>
                <div class="d-flex" style="gap: 8px;">
                    <input type="checkbox" id="reg-offer" required>
                    <label for="reg-offer" class="offer">Я согласен с условиями <a href="/Договор_публичной_офферты.pdf" target="__blank">Публичной оферты</a></label>
                </div>
            </div>
            <button class="modal-login__button subs" type="submit">Купить</button>
        </form>
        <div class="bg-blur"></div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/api'

import Multiselect from '@vueform/multiselect'

export default {
    name: 'modalLogin',

    components: {
        Multiselect
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        type: {
            default: ''
        },

        location: {
            default: ''
        },

        selectDisabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            optionsLocation: [],

            data: {
                purpose: 'Покупка абонемента',
                type: '',
                name: '',
                birth_date: '',
                phone: '',
                email: '',
                selectLocation: '',
            }
        }
    },

    methods: {
        hideModal() {
            this.$emit('close')
        },

        sendRequest(e) {
            e.preventDefault()
            this.data.type = this.type

            let birth_date = this.data.birth_date.split('.')
            this.data.birth_date = birth_date[2] + '-' + birth_date[1] + '-' + birth_date[0]

            axios
            .post(api + "/site-requests/", this.data)
            .then((response) => {
                response.data
                this.$emit('success', {
                    purpose: 'Покупка абонемента',
                    type: this.data.type,
                })
                this.hideModal()
            })
            .catch((error) => {
                console.log(error);
            });
        },

        async fetchLocations() {
            try {
                const response = await axios.get(api + '/shops/')
                this.optionsLocation = []
                for(let i = 0; i < response.data.length; i++) {
                    this.optionsLocation.push(response.data[i].name)
                    
                }
            } catch(err) { // Если есть ошибка
                console.log(err);
			}
        }
    },

    updated() {
        if(this.selectDisabled) {
            this.selectLocation = this.location
            this.optionsLocation = []
            this.optionsLocation.push(this.selectLocation)
        } else {
            this.fetchLocations()
        }
    },

    mounted() {
        this.fetchLocations()
    }
}
</script>