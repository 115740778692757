<template>
    <div class="modal-wrapper" v-if="show">
        <div class="modal">
            <div class="modal-left">
                <button class="modal-close modal-close_sm" @click="hideModal">
                    <span class="icon-close"></span>
                </button>
                <p class="modal-left__title">Запись на занятие</p>
                <p class="modal-left__type">{{ session.product.name }}</p>
                <div class="modal-left__time">
                    <p>{{ session.date[8] }}{{ session.date[9] }}.{{ session.date[5] }}{{ session.date[6] }} в {{ session.start_time[0] }}{{ session.start_time[1] }}:{{ session.start_time[3] }}{{ session.start_time[4] }}</p>
                    <p>Тренер - <span>{{ session.trainer.name }}</span></p>
                </div>
                <form @submit.prevent="sendRespond()">
                    <input v-model="respondData.name" type="text" class="modal-input" placeholder="Ваше Имя" required>
                    <input v-model="respondData.email" type="email" class="modal-input" placeholder="Email" required>
                    <input v-model="respondData.phone" type="tel" class="modal-input" v-mask="'+7 (###) ###-##-##'" placeholder="Телефон" required>
                    <multiselect class="subs" v-model="respondData.visit" :options="['Пробная тренировка - 600 руб','Разовая тренировка - 800 руб']" placeholder="Выберите вид тренировки" required></multiselect>
                    <div class="d-flex" style="gap: 8px;">
                        <input type="checkbox" id="reg-offer" required>
                        <label for="reg-offer" class="offer">Я согласен с условиями <a href="/Договор_публичной_офферты.pdf" target="__blank">Публичной оферты</a></label>
                    </div>
                <button class="modal__button" type="submit">Записаться</button>
                </form>
            </div>
            <div class="modal-right">
                <div class="tabs">
                    <input class="tabs-input" name="tabs" type="radio" id="tab-2" checked>
                    <label class="tabs-label" for="tab-2">О тренере</label>
                    <div class="tabs-panel tabs-panel-trainer">
                        <div class="tabs-panel-trainer__info">
                            <div
                                class="tabs-panel-trainer__info_photo"
                                :style="{ backgroundImage: 'url(' + session.trainer.photo + ')' }"
                            ></div>
                            <div class="col">
                                <div class="d-flex">
                                    <span class="icon-check"></span>
                                    <p><span>{{ session.trainer.experience }}</span> тренировочного опыта</p>
                                </div>
                                <div class="d-flex">
                                    <span class="icon-check"></span>
                                    <p><span>{{ session.trainer.seniority }}</span> тренерского стажа</p>
                                </div>
                            </div>
                        </div>
                        <div class="tabs-panel-trainer__text">
                            <p><span>Дисциплины: </span>{{ session.trainer.disciplines }}</p>
                            <p><span>Принципы: </span> {{ session.trainer.principles }}</p>
                        </div>
                    </div>
                    <input class="tabs-input" name="tabs" type="radio" id="tab-3">
                </div>
            </div>
            <button class="modal-close" @click="hideModal">
                <span class="icon-close"></span>
            </button>
        </div>
        <div class="bg-blur"></div>
    </div>
</template>
<script>
    import Multiselect from '@vueform/multiselect'

    import api from '@/api'
    import axios from 'axios'
    export default {
        name: 'modal',

        components: {
            Multiselect
        },

        props: {
            show: {
                type: Boolean,
                default: false
            },
            session: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                respondData: {
                    purpose: 'Запись на занятие',
                    type: '',
                    date: '',
                    time: '',
                    name: '',
                    email: '',
                    phone: '',
                    visit: ''
                }, 
            }
        },

        methods: {
            hideModal() {
                this.$emit('close')
            },

            sendRespond() {
                this.respondData.type = this.session.product.name
                this.respondData.date = this.session.date
                this.respondData.time = this.session.start_time

                axios
                .post(api + "/site-requests/", this.respondData)
                .then((response) => {
                    this.$emit('success', this.respondData)
                    this.hideModal()
                })
                .catch((error) => {
                    console.log(error);
                });
            },
        }
    }
</script>