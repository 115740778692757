<template>
    <div class="certif-wrapper">
        <div class="certif">
            <button class="modal-close modal-close_sm" @click="hideModal">
                <span class="icon-close"></span>
            </button>
            <p>Подарочный сертификат может быть оформлен на любую сумму. Приобрести можно по номеру</p>
            <a href="https://api.whatsapp.com/send/?phone=79673738333&text=Здравствуйте%2C+хочу+приобрести+сертификат" target="_blank">+7(967)373-83-33</a>
        </div>
        <div class="bg-blur"></div>
    </div>
</template>
<script>
export default {
    name: 'PopupCertif',
    methods: {
        hideModal() {
            this.$emit('close')
        },
    }
}
</script>