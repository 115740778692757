<template>
    <div class="modal-wrapper" v-if="show">
        <div class="modal-login">
            <button class="modal-close modal-close_sm" @click="hideModal">
                <span class="icon-close"></span>
            </button>
            <div class="modal-login__title">
                <span>Личный кабинет</span>
                <p v-if="login">Авторизация</p>
                <p v-else>Регистрация</p>
            </div>
            <form v-if="login && !verInputCode" class="modal-login__form" v-on:submit="userAuth">
                <input type="tel" class="modal-input" v-model="userData.phone" placeholder="Телефон" v-mask="'+7 ### ### ## ##'" required>
                <button class="modal-login__button" type="submit">Отправить код</button>
            </form>
            <form v-if="!login && !verInputCode" class="modal-login__form" v-on:submit="userAuth">
                <input type="text" class="modal-input" v-model="userData.fio" placeholder="ФИО">
                <input type="tel" class="modal-input" v-model="userData.birth_date" placeholder="Дата рождения" v-mask="'##.##.####'">
                <input type="tel" class="modal-input" v-model="userData.phone" placeholder="Телефон" v-mask="'+7 ### ### ## ##'" required>
                <div class="d-flex" style="gap: 8px;">
                    <input type="checkbox" id="reg-offer" required>
                    <label for="reg-offer" class="offer">Я согласен с условиями <a href="/Договор_публичной_офферты.pdf" target="__blank">Публичной оферты</a></label>
                </div>
                <button class="modal-login__button" type="submit">Регистрация</button>
            </form>
            <div v-if="verInputCode" class="modal-login__form">
                <p class="modal-login__code-text_1">Введите код подтверждения</p>
                <p class="modal-login__code-text_2">Мы отправили его на номер {{ userData.phone }}</p>
                <input type="tel" class="modal-input modal-login__code-input" v-model="verCode" placeholder="0000" v-mask="'#####'">
                <p v-if="verCodeError" class="modal-login__code-error">Неверный код!</p>
                <button class="modal-login__button" @click="validateCode">Отправить</button>
            </div>
        </div>
        <div class="bg-blur"></div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/api'

export default {
    name: 'modalLogin',

    props: {
        show: {
            type: Boolean,
            default: false
        },
        login: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            userData: {
                phone: null,
                fio: null,
                birth_date: null,
            },
            verCode: null,
            verInputCode: false,
            verCodeError: false
        }
    },

    methods: {
        hideModal() {
            this.$emit('close')
        },

        userAuth(e) {
            if(e !== 'repeat') {
                e.preventDefault()
            }

            let phone = this.userData.phone.replace(/\s+/g, '').slice(1)

            if (this.phone !== null) {
                axios
                .post(api + "/auth/phone/", {
                    phone: phone
                })
                .then((response) => {
                    this.verInputCode = true;
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }
        },

        validateCode() {
            const phone = this.userData.phone.replace(/\s+/g, '').slice(1)

            axios
            .post(api + "/auth/verification/", {
                phone: phone,
                code: this.verCode,
            })
            .then((response) => {
                this.$emit('auth', response.data.token)
                this.verCodeError = false
                if(this.userData.fio !== null) {
                    this.addUserName(response.data.token)
                }
            })
            .catch((error) => {
                console.log(error.response);
                this.verCodeError = true
            });
        },

        addUserName(token) {
            let userBirthday = this.userData.birth_date.split('.')
            let newUserData = {
                first_name: this.userData.fio,
                last_name: this.userData.fio,
                birthday: userBirthday[2] + '-' + userBirthday[1] + '-' + userBirthday[0]
            }

            axios
            .post(api + "/profile/", newUserData, {
                headers: {
                    Authorization: 'Token ' + token
                },
            })
            .then(() => {
            })
            .catch((error) => {
                console.log(error.response);
            });
        },
    }
}
</script>